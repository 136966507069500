// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/shared/polyfills';
// Notifications plugin. Used on Notifications page
import Notifications from '@/shared/components/NotificationPlugin';
// Validation plugin used to validate forms
import VeeValidate from 'vee-validate';
import veeValidateConfig from './veeValidateConfig';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/shared/components/SidebarPlugin';

// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import 'element-ui/lib/theme-chalk/index.css';
locale.use(lang);

// asset imports
import '@/shared/assets/sass/argon.scss';
import '@/shared/assets/css/nucleo/css/nucleo.css';
import '@/shared/assets/fontawesome-free/css/all.css';

import moment from 'moment'
import VueMomentJS from 'vue-momentjs'
moment.locale("ko");

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(VeeValidate, veeValidateConfig);
    Vue.use(VueMomentJS, moment);
  }
};

<template>
  <label class="custom-toggle" :class="switchClass">
    <input type="checkbox" v-model="model" :disabled="disabled" />
    <span
      class="custom-toggle-slider rounded-circle"
      :data-label-off="offText"
      :data-label-on="onText"
    >
    </span>
  </label>
</template>
<script>
export default {
  name: "base-switch",
  props: {
    value: [Array, Boolean],
    type: String,
    onText: {
      type: String,
      default: "Yes"
    },
    offText: {
      type: String,
      default: "No"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    switchClass() {
      const customClass = {
        disabled: this.disabled
      };
      if (this.type) {
        customClass[`custom-toggle-${this.type}`] = true;
      }
      return customClass;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style scoped>
/* label.custom-toggle.disabled {
  opacity: 0.6;
  cursor: not-allowed;
} */
label.custom-toggle.disabled > input:checked ~ span {
  /* cursor: not-allowed; */
  border: 1px solid #5e72e4;
}
label.custom-toggle.disabled > input:checked ~ span::before {
  /* cursor: not-allowed; */
  background-color: #5e72e4;
}
label.custom-toggle.custom-toggle-confirm.disabled {
  opacity: 1 !important;
  cursor: pointer !important;
}
label.custom-toggle.custom-toggle-confirm.disabled > input:checked ~ span {
  cursor: pointer !important;
}
label.custom-toggle.custom-toggle-confirm.disabled
  > input:checked
  ~ span::before {
  cursor: pointer !important;
}
</style>

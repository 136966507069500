<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{'bg-kakao navbar-dark': type === 'default'}"
  >
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
            <div class="media-body ml-2 d-none d-lg-block text-kakao">
              <span class="mb-0 font-weight-bold">{{ session.name }}</span><span class="mb-0 text-sm">님 반갑습니다.</span>
            </div>
          </div>
          <div class="media align-items-center" v-if="prepaymentYn == 'Y'">
            <div class="media-body ml-2 d-none d-lg-block text-kakao">
              <span class="mb-0 text-sm">Credit(사용/총) : {{ usageCredit | convertCommaNumber }} / {{ credit | convertCommaNumber }}</span>
            </div>
          </div>
        </a>

        <template>
          <a v-if="session.userId !== 'kepbzm@gmail.com'" href="#!" class="dropdown-item" @click="openModifyLayer">
            <i class="ni ni-single-02"></i>
            <span>내 정보 수정</span>
          </a>
          <a v-if="profileDispYn == 'Y'" href="#!" class="dropdown-item" @click="openProfileLayer">
            <i class="ni ni-single-02"></i>
            <span>프로필 조회</span>
          </a>
          <router-link to="/user/logout" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>로그아웃</span>
          </router-link>
        </template>
      </base-dropdown>
    </ul>

    <!-- 사용자 수정 레이어 -->
    <users-modify-layer v-if="showModifyLayer"
                        :seqno="seqno"
                        :myInfoYn="this.myInfoYn"
                        @close="closeLayer">
    </users-modify-layer>
    <profile-view-layer v-if="showProfileLayer"
                        :seqno="seqno"
                        :myInfoYn="this.myInfoYn"
                        @close="closeLayer">
    </profile-view-layer>
  </base-nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import { BaseNav, Modal } from '@/shared/components';
  import { mapGetters } from "vuex";
  import UsersModifyLayer from '../Users/UsersModifyLayer';
  import ProfileViewLayer from "../SenderProfile/ProfileViewLayer";
  import {USER_API_PREFIX} from "../../../shared/util/const";

  export default {
    components: {
      CollapseTransition,
      BaseNav,
      Modal,
      UsersModifyLayer,
      ProfileViewLayer
    },
    props: {
      type: {
        type: String,
        default: 'default', // default|light
        description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
      }
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      ...mapGetters({
        session: 'SessionStore/getSession'
      })
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        showModifyLayer: false,
        showProfileLayer: false,
        seqno: undefined,  // 사용자 수정 시, 선택된 사용자의 고유번호
        myInfoYn: 'N',
        profileDispYn: 'N',
        prepaymentYn : 'N',
        usageCredit : 0,
        credit : 0
      };
    },
    created() {
      this.getProfileDispYn()

      //선불금액 표시 - 발송 후 리로딩 문제로 주석처리, 차후 필요시에 사용
      //this.getPrepaymentInfo()
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      /**
       * 프로필 조회 메뉴 표시여부
       */
      getProfileDispYn() {
        if (this.session.clientType !== 'R') {
          let url = `${USER_API_PREFIX}/sender/profile-list-cnt`;

          this.$axios.get(url, {
            params: {}
          }).then(res => {
            this.profileDispYn = (res.data.result.cnt > 0) ? 'Y' : 'N'
          });
        }
      },
      /**
       * 선불 고객 credit 정보 표시 - 선불고객의 경우에만 표시
       */
      getPrepaymentInfo() {
        this.$axios.get(`${USER_API_PREFIX}/prepayment/client/${this.session.clientId}`, {
        }).then(res => {
          if(res.data.result.prepaymentClientInfo){
            this.prepaymentYn = 'Y';
            this.usageCredit = res.data.result.prepaymentClientInfo.usageCredit;
            this.credit = res.data.result.prepaymentClientInfo.credit;
          }
        });
      },
      /**
       * 수정 레이어 생성
       */
      openModifyLayer() {
        this.showModifyLayer = true;
        this.seqno = this.session.seqno;
        this.myInfoYn = 'Y'
      },
      /**
       * 프로필 조회 레이어 생성
       */
      openProfileLayer() {
        this.showProfileLayer = true;
        this.seqno = this.session.seqno;
        this.myInfoYn = 'Y'
      },
      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.showLayer = false;
        this.showModifyLayer = false;
        this.showProfileLayer = false;
      }
    }
  };
</script>

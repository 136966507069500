import moment from 'moment';

export function convertCommaNumber (value) {
  if (!value) return '0'
  return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
}

export function nullToHyphen (value) {
  if (value) {
    return value
  }
  return '-'
}

export function convertDateFormat (value) {
  if (value) {
    // return moment(new Date(value)).format('YYYY-MM-DD')
    return moment(new Date(value[0], value[1] - 1, value[2])).format('YYYY-MM-DD')
  } else {
    return value
  }
}

export function convertDatetimeFormat (value) {
  if (value) {
    return moment(new Date(value[0], value[1] - 1, value[2], value[3], value[4], value[5] === undefined ? 0 : value[5])).format('YYYY-MM-DD HH:mm:ss')
  } else if (value === null) {
    return '-'
  } else {
    return value
  }
}

export function convertDatetimeKrFormat(value) {
  if (value) {
    return moment(value).format('YYYY.MM.DD (ddd) HH:mm:ss');
  } else if (value === null) {
    return '-'
  } else {
    return value
  }
}

export function phoneNumberFormat (value) {
  if (value) {
    let formatNum = ''
    let num = value.replace(/[^0-9]/g, '')
    if (num.length < 8) {
      formatNum = num.replace(/(\d{3})(\d{1})/, '$1-$2')
    } else if (num.length === 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
    } else if (num.length === 9) {
      formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
    } else if (num.length === 10) {
      if (num.indexOf('02') === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      }
    } else if (num.length === 11) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
    } else {
      formatNum = value.substring(0, 13)
    }
    return formatNum;
  } else {
    return '-';
  }
}

<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot-scope="props" slot="links">

        <sidebar-item v-for="item in session.authorizedMenuList" :key="item.seqno" :link="makeMenuLink(item)" >
          <sidebar-item v-for="subItem in item.subMenuList" :key="subItem.seqno" :link="makeMenuLink(subItem)"/>
        </sidebar-item>

      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import { mapGetters } from 'vuex';
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';
  import { USER_API_PREFIX, OTP_AUTH_YN } from '@/shared/util/const';

  export default {
    components: {

      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      }),
      isDevelopment() {
        return process.env.NODE_ENV === 'development';
      },
      useBrandtalk() {
        return this.session.brandtalkYn === 'Y';
      }
    },
    created() {
      // OTP 인증시 처리
      let otpPersonalYn = sessionStorage.getItem('otpPersonalYn');
      let otpAuthYn = `${OTP_AUTH_YN}`
      if(otpAuthYn === 'Y' && otpPersonalYn === 'Y') {
        sessionStorage.clear();
        localStorage.clear();
        location.href = `${USER_API_PREFIX}/logout-process`;
        return
      }

      // 기존 세션 스토리지 초기화
      sessionStorage.clear();
      sessionStorage.setItem('path', `${this.$route.fullPath}`);
    },
    methods: {
      makeMenuLink(item) {
        if(item.path) {
          return { name: item.menuName, icon: item.iconClass, path: item.path };
        } else {
          return { name: item.menuName, icon: item.iconClass }
        }
      },
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('scrollbar-inner');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
  .bg-kakao{
    background-color:  #FFCD00 !important;
  }
  .text-kakao{
    color: #433530 !important;
  }
  .breadcrumb-item a{
    color: #433530 !important;
  }
  .breadcrumb-item.active{
    color: #433530 !important;
  }
  .breadcrumb-item::before{
    color: #433530 !important;
  }
  .nav-link{
    color: #433530 !important;
  }
  .navbar-light .navbar-nav .nav-link.active {
    font-weight: bold;
  }

</style>

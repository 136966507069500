<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">프로필 조회</h6>

    <template>
      <form role="form" class="needs-validation">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">채널아이디</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.profileInfo.channelId }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">발신프로필명</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.profileInfo.channelName }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">발신프로필키 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.profileInfo.senderKey }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">대분류</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.categoryName0 }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">중분류</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.categoryName1 }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">소분류</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.categoryName2 }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">상태</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.profileInfo.status | statusFilter }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">휴면 상태</label>
          <div class="col-md-9">
            <div class="form-group" v-if="this.profileInfo.dormant==true">
              <p class="form-control" style="border: 0">
                <base-button type="danger" @click="recoverProfile" size="sm">해제</base-button>
              </p>
            </div>
            <div class="form-group" v-else>
              <p class="form-control" style="border: 0">정상</p>
            </div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">차단 상태</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.profileInfo.block | blockFilter }}</p></div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">등록일시</label>
          <div class="col-md-9">
            <div class="form-group"><p class="form-control" style="border: 0">{{ this.profileInfo.regDate | convertDatetimeFormat }}</p></div>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="col-md-9">
        </div>
        <div class="col-md-3">
          <base-button type="link" @click="closeLayer">닫기</base-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE_LiST } from '@/shared/util/const';

  export default {
    name: 'ProfileViewLayer',
    mixins: [ commJsUtilMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    filters: {
      statusFilter: value => {
        let name = '';
        switch (value) {
          case 'A':
            name = '정상';
            break;
          case 'S':
            name = '차단';
            break;
          case 'D':
            name = '삭제';
            break;
        }
        return name;
      },
      blockFilter: value => {
        let name = '정상';
        if (value) {
          name = '차단';
        }
        return name;
      }
    },
    computed: {
      statusCodeList() {
        return STATUS_CODE_LiST;
      }
    },
    data() {
      return {
        profileInfo : [],
        categoryCode : '',
        categoryName0 : '',
        categoryName1 : '',
        categoryName2 : '',
      }
    },
    created() {
      this.getProfileInfo()
    },
    methods: {
      /**
       * 발신프로필 목록조회
       */
      getProfileInfo() {
        this.$axios.get(`${USER_API_PREFIX}/sender/profile-info`, {
        }).then(res => {
          this.profileInfo            = res.data.result.profileInfo;    // 목록정보 세팅
          this.categoryCode           = this.profileInfo.categoryCode;

          this.getCategoryInfo(this.categoryCode);
        });
      },
      /**
       * 카테고리 정보 가져오기
       */
      getCategoryInfo(categoryCode) {
        if(categoryCode){
          this.$axios.get(`${USER_API_PREFIX}/sender/category-info/${categoryCode}`, {
            params : {
            }
          }).then(res => {
            let categoryInfo = res.data.result.categoryInfo.name.split(',');
            this.categoryName0 = categoryInfo[0];
            this.categoryName1 = categoryInfo[1];
            this.categoryName2 = categoryInfo[2];
          });
        }
      },
      /**
       * 휴면 해제 이벤트 실행
       */
      recoverProfile() {
        this._swalQuestion({ title:'휴면 해제 하시겠습니까?' }, () => {
          this.$axios.put(`${USER_API_PREFIX}/sender/recover`, {
            senderKey: this.profileInfo.senderKey
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.notify('default', res.data.result.message);
              this.getProfileInfo();
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close', 'N');
      }
    }
  };
</script>

<style>
</style>

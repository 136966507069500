// Back-End 사용자 API URI Prefix
export const USER_API_PREFIX = `${process.env.VUE_APP_USER_API_PREFIX}`;
// Back-End 관리자 API URI Prefix
export const ADMIN_API_PREFIX = `${process.env.VUE_APP_ADMIN_API_PREFIX}`;

// Back-End REST URI
export const VUE_REST_API_URL = `${process.env.VUE_APP_REST_API_URL}`;
// Back-End REST URI2
export const VUE_APP_REST_MANAGER_API_URL = `${process.env.VUE_APP_REST_MANAGER_API_URL}`;
// Back-End REST API URI Prefix
export const REST_API_PREFIX = `${process.env.VUE_APP_REST_API_PREFIX}`;

// USER Web Socket URL
export const USER_WEBSOCKET_URL = `${process.env.VUE_APP_WEBSOCKET_USER_URL}`;
// ADMIN Web Socket URL
export const ADMIN_WEBSOCKET_URL = `${process.env.VUE_APP_WEBSOCKET_ADMIN_URL}`;

export const OTP_AUTH_YN = `${process.env.VUE_APP_OTP_AUTH_YN}`;

// 결과 코드
export const RESULT_CODE = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

// 사용자 상태 코드
export const STATUS_CODE = {
  AVAILABLE: 'A',
  DISABLED: 'D',
  LOCK: 'L'
};

// 사용자 상태 코드
export const NOTISTATUS = {
  NODATA: 'N',
  DATA: 'Y'
};

// 사용자 상태 코드
export const STATUS_CODE_LiST = [
  {
    label: '정상',
    value: STATUS_CODE.AVAILABLE
  },
  {
    label: '잠김',
    value: STATUS_CODE.LOCK
  },
  {
    label: '탈퇴',
    value: STATUS_CODE.DISABLED
  }
];

export const ROLE_CODE_LIST = [
  {
    label: '운영자',
    value: 'O'
  },
  {
    label: '슈퍼관리자',
    value: 'M'
  }
];


// 발송 형식 구분 (알림톡, 친구톡, 문자메시지)
export const MESSAGE_TYPE = {
  ALARM: 'A',
  FRIEND: 'F',
  SMS: 'S',

};

// 웹발송 형식 구분 (알림톡, 친구톡, SMS, LMS, MMS)
export const MESSAGE_TYPES = [
  {
    label : '알림톡',
    value : 'AT'
  },
  {
    label: '친구톡',
    value : 'FT'
  },
  {
    label : 'SMS',
    value : 'SM'
  },
  {
    label : 'LMS',
    value : 'LM'
  },
  {
    label : 'MMS',
    value : 'MM'
  }
];

// 부달 형식 구분 (SNS, LMS, MMS)
export const SMS_TYPES = [
  {
    label : 'SMS',
    value : 'SM'
  },
  {
    label : 'LMS',
    value : 'LM'
  },
  {
    label : 'MMS',
    value : 'MM'
  }
];

// 부달 형식 구분 (SMS, LMS)
export const SUB_SMS_TYPES = [
  {
    label : 'SMS',
    value : 'SM'
  },
  {
    label : 'LMS',
    value : 'LM'
  }
];

// Button Types
export const FRIEND_BUTTON_TYPES = [
  {
    label: '웹링크',
    value: 'WL'
  },
  {
    label: '앱링크',
    value: 'AL'
  },
  {
    label: '봇키워드',
    value: 'BK'
  },
  {
    label: '메시지전달',
    value: 'MD'
  }
];

// 템플릿 link type
export const TEMPLATE_LINK_TYPE = {
  BUTTON: {
    BASIC: [
      {
        label: '웹링크',
        value: 'WL'
      },{
        label: '앱링크',
        value: 'AL'
      },{
        label: '배송조회',
        value: 'DS'
      },{
        label: '봇키워드',
        value: 'BK'
      },{
        label: '메시지전달',
        value: 'MD'
      },{
        label: '상담톡전환',
        value: 'BC'
      },{
        label: '봇전환',
        value: 'BT'
      },{
        label: '이미지 보안 전송 플러그인',
        value: 'P1'
      },{
        label: '개인정보이용 플러그인',
        value: 'P2'
      },{
        label: '원클릭 결제 플러그인',
        value: 'P3'
      }
    ],
    ADV: [
      {
        label: '채널추가',
        value: 'AC'
      },{
        label: '웹링크',
        value: 'WL'
      },{
        label: '앱링크',
        value: 'AL'
      },{
        label: '배송조회',
        value: 'DS'
      },{
        label: '봇키워드',
        value: 'BK'
      },{
        label: '메시지전달',
        value: 'MD'
      },{
        label: '상담톡전환',
        value: 'BC'
      },{
        label: '봇전환',
        value: 'BT'
      },{
        label: '이미지 보안 전송 플러그인',
        value: 'P1'
      },{
        label: '개인정보이용 플러그인',
        value: 'P2'
      },{
        label: '원클릭 결제 플러그인',
        value: 'P3'
      }
    ]
  },
  QUICK: [
    {
      label: '웹링크',
      value: 'WL'
    },{
      label: '앱링크',
      value: 'AL'
    },{
      label: '봇키워드',
      value: 'BK'
    },{
      label: '메시지전달',
      value: 'MD'
    },{
      label: '상담톡전환',
      value: 'BC'
    },{
      label: '봇전환',
      value: 'BT'
    }
  ]
}

// 템플릿 메시지유형
export const TEMPLATE_MESSAGE_TYPE = [
  {
    label: '기본',
    value: 'BA'
  },{
    label: '부가 정보형',
    value: 'EX'
  },{
    //label: '광고 추가형',
    label: '채널 추가형',
    value: 'AD'
  },{
    label: '복합형',
    value: 'MI'
  }
]

// 템플릿 강조유형
export const TEMPLATE_EMPHASIZE_TYPE = [
  {
    label: '선택안함',
    value: 'NONE'
  },{
    label: '강조표기형',
    value: 'TEXT'
  },{
    label: '이미지형',
    value: 'IMAGE'
  },{
    label : '아이템리스트형',
    value : 'ITEM_LIST'
  }
]

export const SITE_CODE = {
  USER: '01',
  ADMIN: '02'
};

export const STEP_STATUS = {
  A: 'A', // 1단계 : 등록
  B: 'B', // 2단계 : 1차 검수 요청
  C: 'C', // 3단계 : 1차 승인
  D: 'D', // 4단계 : 2차 검수 요청
  E: 'E', // 5단계 : 반려 내용 입력 대기중
  F: 'F', // 6단계 : 최종 반려
  G: 'G' // 7단계 : 최종 승인
};

export const KEP_STATUS = {
  N: 'N', // 등록
  I: 'I', // 검수 진행 중 상태
  O: 'O', // 최종 승인
  R: 'R' // 최종 반려
};

export const INSPECTION_STATUS = {
  REG: 'REG', // 등록
  REQ: 'REQ', // 검수 요청
  APR: 'APR', // 승인
  REJ: 'REJ' // 반려
};

export const TEMPLATE_STATUS = {
  S: 'S', // 중지
  A: 'A', // 정상
  R: 'R' // 대기(발송전)
};

export const TASK_DIVISION = {
  T: 'T', // 템플릿 관련 업무 구분
  S: 'S', // 발신번호 관련 업무 구분
};

export const LIMIT_MENU_DEPTH = 2
export const DEFAULT_MENU_NAME = '메뉴명을 입력하세요.'

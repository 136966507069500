<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">비밀번호 변경</h6>
    <template>
      <form role="form" class="needs-validation">
        <div class="form-group row mb-0" style="margin-top: -1rem;">
          <label class="col-md-3 col-form-label form-control-label">기존 비밀번호<span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.userPassword"
                        type="password"
                        name="userPassword"
                        ref="password"
                        :maxlength="20"
                        :error="_getError('userPassword')"
                        v-validate="'required|password'"
                        placeholder="비밀번호 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0" style="margin-top: -1rem;">
          <label class="col-md-3 col-form-label form-control-label">변경 비밀번호<span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.newPassword"
                        type="password"
                        name="password"
                        ref="password"
                        :maxlength="20"
                        :error="_getError('password')"
                        v-validate="'required|password'"
                        placeholder="비밀번호 입력"
                        autocomplete="new-password">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0" style="margin-top: -1rem;">
          <label class="col-md-3 col-form-label form-control-label">비밀번호 확인<span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input placeholder="비밀번호 확인"
                        type="password"
                        name="confirmedPassword"
                        :maxlength="20"
                        :error="_getError('confirmedPassword')"
                        v-validate="'required|confirmed:password'"
                        v-model="model.confirmedPassword"
                        autocomplete="new-password">
            </base-input>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="applyChangePassword">변경</base-button>
    </template>

    <p class="mt-0 mb-0">
      <small>비밀번호는 영문 대문자, 영문 소문자, 숫자, 특수문자 중 <span class="text-danger font-weight-bold">2종류 이상을 조합하여 최소 10자리 이상, 또는 3종류 이상을 조합하여 최소 8자리 이상, 최대 20자 길이</span>로 구성 해야 합니다.</small>
    </p>
    <p class="mb-0">
      <small>특수문자 중 <span class="text-danger font-weight-bold">" ' \ [ ] & Space(공백문자) ? % 사용하실 수 없습니다.</span></small>
    </p>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'UsersPasswordLayer',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    props: {
      userId : String
    },
    computed: {
    },
    data() {
      return {
        model: {
          userPassword      : '',
          newPassword       : '',
          confirmedPassword : ''
        },
      }
    },
    created() {
    },
    methods: {
      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },

      /**
       * 비밀번호 변경 적용
       */
      applyChangePassword() {
        let url = `${USER_API_PREFIX}/my-info/change-password`;

        this._validateBeforeSubmit(() => {
          if(this.model.userPassword) {
            this._swalQuestion({ title:'비밀번호를 수정 하시겠습니까?' }, () => {
              this.$axios.put(url, {
                userId       : this.userId ,
                userPassword : this.model.userPassword ,
                newPassword  : this.model.newPassword
              }).then(res => {
                if (res.data.result.code === RESULT_CODE.SUCCESS) {
                  this.notify('default', res.data.result.message);
                  this.model.userPassword = '';
                  this.model.newPassword = '';
                  this.model.confirmedPassword = '';
                  this.closeLayer();
                } else {
                  this.notify('warning', res.data.result.message);
                }
              });
            });
          }
        });
      },
    }
  };
</script>

<style>
</style>

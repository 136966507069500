import BaseInput from '@/shared/components/Inputs/BaseInput.vue';
import BaseDropdown from '@/shared/components/BaseDropdown.vue';
import Card from '@/shared/components/Cards/Card.vue';
import Modal from '@/shared/components/Modal.vue';
import StatsCard from '@/shared/components/Cards/StatsCard.vue';
import BaseButton from '@/shared/components/BaseButton.vue';
import Badge from '@/shared/components/Badge.vue';
import RouteBreadcrumb from '@/shared/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/shared/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/shared/components/BaseSwitch.vue';
import BaseRadio from "@/shared/components/Inputs/BaseRadio";
import BaseProgress from "@/shared/components/BaseProgress";
import BasePagination from "@/shared/components/BasePagination";
import BaseAlert from "@/shared/components/BaseAlert";
import BaseNav from "@/shared/components/Navbar/BaseNav";
import BaseHeader from '@/shared/components/BaseHeader';
import Tabs from '@/shared/components/Tabs/Tabs';
import Tab from '@/shared/components/Tabs/Tab';
import ButtonRadioGroup from '@/shared/components/ButtonRadioGroup';

import { Input, Tooltip, Popover } from 'element-ui';

import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Editor, Viewer } from '@toast-ui/vue-editor'

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(Tabs.name, Tabs);
    Vue.component(Tab.name, Tab);
    Vue.component(Input.name, Input);
    Vue.component(ButtonRadioGroup.name, ButtonRadioGroup);
    Vue.component('editor', Editor);
    Vue.component('viewer', Viewer);
    Vue.use(Tooltip);
    Vue.use(Popover);
  }
};

export default GlobalComponents;

<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 v-if="this.myInfoYn === 'Y'" slot="header" class="modal-title">내정보 수정</h6>
    <h6 v-else slot="header" class="modal-title">사용자 수정</h6>

    <template>
      <form role="form" class="needs-validation">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">아이디</label>
          <div class="col-md-9">
            <base-input v-model="model.userId"
                        input-classes="display-view-text"
                        disabled
                        autocomplete="username">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0" style="margin-top: -0.5rem">
          <label class="col-md-3 col-form-label form-control-label">비밀번호</label>
          <div class="col-md-9" v-if="this.myInfoYn === 'Y'">
            <base-button type="primary" @click="openPasswordLayer()" size="sm">비밀번호 변경하기</base-button>
          </div>
          <div class="col-md-9" v-else>
            <base-input group :input-group-classes="'width-100p'"
                        v-model="model.userPassword"
                        name="password"
                        :error="_getError('password')"
                        v-validate="'password'"
                        autocomplete="new-password">
              <input type="password" class="form-control" placeholder="비밀번호 변경 시, 입력 가능" aria-label="비밀번호 변경 시, 입력 가능" aria-describedby="button-addon2"
                     ref="password"
                     :disabled="!changePasswordFlag"
                     v-model="model.userPassword"
                     maxlength="20"
                     autocomplete="new-password">
              <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" v-if="!changePasswordFlag" @click="doChangePassword">변경</button>
                <button class="btn btn-outline-danger" type="button" v-else @click="applyChangePassword">적용</button>
              </div>
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0" style="margin-top: 0.6rem">
          <label class="col-md-3 col-form-label form-control-label">이름 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.name"
                        name="name"
                        :error="_getError('name')"
                        :maxlength="10"
                        v-validate="'required'"
                        placeholder="이름 입력">
            </base-input>
          </div>
        </div>
        <div v-if="this.myInfoYn !== 'Y'" class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">상태 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input>
              <el-select v-model="model.status"
                         filterable
                         placeholder="검색 조건">
                <el-option v-for="option in statusCodeList"
                           :key="option.label"
                           :label="option.label"
                           :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">직무</label>
          <div class="col-md-9">
            <base-input v-model="model.duty"
                        :maxlength="10"
                        placeholder="직무 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">직위</label>
          <div class="col-md-9">
            <base-input v-model="model.position"
                        :maxlength="10"
                        placeholder="직위 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">전화번호</label>
          <div class="col-md-9">
            <base-input v-model="model.phoneNo"
                        placeholder="전화번호 입력"
                        :maxlength="13"
                        @keyup="phoneMask('phone')">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">휴대폰번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.mobileNo"
                        name="mobileNo"
                        :error="_getError('mobileNo')"
                        :maxlength="13"
                        v-validate="'required'"
                        placeholder="휴대폰번호 입력"
                        @keyup="phoneMask('mobile')">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">OTP 인증 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input>
              <base-radio v-model="model.otpYn" class="mt-1 mb-3" name="Y" :inline="true">예</base-radio>
              <base-radio v-model="model.otpYn" class="mt-1 mb-3" name="N" :inline="true">아니오</base-radio>
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">공지알림 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input>
              <base-radio v-model="model.noticeYn" class="mt-1 mb-3" name="Y" :inline="true">수신</base-radio>
              <base-radio v-model="model.noticeYn" class="mt-1 mb-3" name="N" :inline="true">미수신</base-radio>
            </base-input>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="processModify" :disabled="changePasswordFlag">수정</base-button>
    </template>

    <p class="mt-0 mb-0">
      <small>비밀번호는 영문 대문자, 영문 소문자, 숫자, 특수문자 중 <span class="text-danger font-weight-bold">2종류 이상을 조합하여 최소 10자리 이상, 또는 3종류 이상을 조합하여 최소 8자리 이상, 최대 20자 길이</span>로 구성 해야 합니다.</small>
    </p>
    <p class="mb-0">
      <small>특수문자 중 <span class="text-danger font-weight-bold">" ' \ [ ] & Space(공백문자) ? % 사용하실 수 없습니다.</span></small>
    </p>

    <users-password-layer
      v-if="showPasswordLayer"
      :userId="model.userId"
      @click="openPasswordLayer"
      @close="closePasswordLayer">
    </users-password-layer>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE, STATUS_CODE_LiST } from '@/shared/util/const';
  import UsersPasswordLayer from './UsersPasswordLayer';

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'UsersRegisterLayer',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      UsersPasswordLayer
    },
    props: {
      seqno: {
        type: Number,
        default: undefined,
        description: '사용자 고유번호'
      },
      myInfoYn: {
        type: String,
        default: undefined,
        description: '내정보수정 메뉴여부'
      }
    },
    computed: {
      statusCodeList() {
        return STATUS_CODE_LiST;
      }
    },
    data() {
      return {
        model: {
          userId        : '',
          userPassword  : '',
          name          : '',
          duty          : '',
          position      : '',
          phoneNo       : '',
          mobileNo      : '',
          roleCode      : '',
          status        : STATUS_CODE.AVAILABLE,
          otpYn         : 'N',
          noticeYn      : 'Y'
        },
        changePasswordFlag  : false,
        showPasswordLayer   : false
      }
    },
    created() {
      this.get()
    },
    methods: {
      /**
       * 비밀번호 변경 레이어 열기
       */
      openPasswordLayer(){
        this.showPasswordLayer = true;
      },
      /**
       * 비밀번호 변경 레이어 열기
       */
      closePasswordLayer(){
        this.showPasswordLayer = false;
      },
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close', 'N');
      },
      /**
       * 사용자 수정 실행
       */
      modify() {
        let url = `${USER_API_PREFIX}/users/${this.seqno}`;
        if(this.myInfoYn === 'Y') {
          url = `${USER_API_PREFIX}/my-info`;
        }

        this._swalQuestion({ title:'수정 하시겠습니까?' }, () => {
          this.$axios.put(url, {
            userId: this.model.userId,
            name: this.model.name,
            status: this.model.status,
            duty: this.model.duty,
            position: this.model.position,
            phoneNo: this.model.phoneNo,
            mobileNo: this.model.mobileNo,
            roleCode: this.model.roleCode,
            otpYn: this.model.otpYn,
            noticeYn : this.model.noticeYn
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.$emit('close', 'Y');
              this.notify('default', res.data.result.message);
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      },
      /**
       * 등록 실행 전, validate check!
       */
      processModify() {
        this._validateBeforeSubmit(this.modify);
      },
      /**
       * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
       */
      phoneMask(target) {
        switch (target) {
          case 'mobile':
            this.model.mobileNo = this._autoHyphenPhoneNumber(this.model.mobileNo);
            break;
          case 'phone':
            this.model.phoneNo= this._autoHyphenPhoneNumber(this.model.phoneNo);
            break;
        }
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      /**
       * 사용자 정보 조회
       */
      get() {
        let url = `${USER_API_PREFIX}/users/${this.seqno}`;
        if(this.myInfoYn === 'Y') {
          url = `${USER_API_PREFIX}/my-info`;
        }

        this.$axios.get(url, {
          params: {}
        }).then(res => {
          let userInfo = res.data.result.userInfo;
          this.model.userId = userInfo.userId;
          this.model.name = userInfo.name;
          this.model.duty = userInfo.duty;
          this.model.position = userInfo.position;
          this.model.phoneNo = this._autoHyphenPhoneNumber(userInfo.phoneNo);
          this.model.mobileNo = this._autoHyphenPhoneNumber(userInfo.mobileNo);
          this.model.status = userInfo.status;
          this.model.roleCode = userInfo.roleCode;
          this.model.otpYn = userInfo.otpYn;
          this.model.noticeYn = userInfo.noticeYn;
        });
      },
      /**
       * 비밀번호 변경 버튼 클릭 시 이벤트
       */
      doChangePassword() {
        this.changePasswordFlag = !this.changePasswordFlag;
      },
      /**
       * 비밀번호 변경 적용
       */
      applyChangePassword() {
        let url = `${USER_API_PREFIX}/users/change-password`;

        this._validateBeforeSubmit(() => {
          if(this.model.userPassword) {
            this._swalQuestion({ title:'비밀번호를 수정 하시겠습니까?' }, () => {
              this.$axios.put(url, {
                seqno: this.seqno,
                newPassword: this.model.userPassword
              }).then(res => {
                if (res.data.result.code === RESULT_CODE.SUCCESS) {
                  this.notify('default', res.data.result.message);
                  this.model.userPassword = '';
                  this.changePasswordFlag = !this.changePasswordFlag;
                } else {
                  this.notify('warning', res.data.result.message);
                }
              });
            });
          }
        });
      },
    }
  };
</script>

<style>
</style>

import axios from "axios";
import { USER_API_PREFIX } from '@/shared/util/const';

// Layout
import DashboardLayout from '@/user/views/Layout/DashboardLayout.vue';

import AuthLayout from '@/user/views/Layout/AuthLayout.vue';
// GeneralViews
import NotFound from '@/user/views/GeneralViews/NotFoundPage.vue';
import NoPermission from '@/user/views/GeneralViews/NoPermissionPage.vue';

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/user/views/Dashboard/Dashboard.vue');

// 계정 관련 페이지
const Login = () => import(/* webpackChunkName: "account" */ '@/user/views/Account/Login.vue'); // 로그인 페이지
const LoginSuccess = () => import(/* webpackChunkName: "account" */ '@/user/views/Account/LoginSuccess.vue'); // 로그인 성공 페이지
const Logout = () => import(/* webpackChunkName: "account" */ '@/user/views/Account/Logout.vue'); // 로그아웃 페이지
const FindId = () => import(/* webpackChunkName: "account" */ '@/user/views/Account/FindId.vue'); // 아이디 찾기 페이지
const FindPassword = () => import(/* webpackChunkName: "account" */ '@/user/views/Account/FindPassword.vue'); // 아이디 찾기 페이지
const OtpAuth = () => import(/* webpackChunkName: "account" */ '@/user/views/Account/OtpAuth.vue'); // OTP 인증 페이지

// 발신자번호 관리 페이지
const CallerId = () => import(/* webpackChunkName: "callerId" */ '@/user/views/CallerId/CallerId.vue');

// 알림톡 템플릿 관리 페이지
const TemplateList = () => import(/* webpackChunkName: "templates" */ '@/user/views/Template/TemplateList.vue');
const TemplateForm = () => import(/* webpackChunkName: "templates" */ '@/user/views/Template/TemplateForm.vue');
const TemplateInfoView = () => import(/* webpackChunkName: "templates" */ '@/user/views/Template/TemplateInfoView.vue');
const TemplateMultiForm = () => import(/* webpackChunkName: "templates" */ '@/user/views/Template/TemplateMultiForm.vue');

const TemplateRestList = () => import(/* webpackChunkName: "template" */ '@/user/views/Template/TemplateRestList.vue');

// 브랜드톡 템플릿 관리 페이지
const BrandtalkTemplateList = () => import(/* webpackChunkName: "brandtalk" */ '@/user/views/BrandtalkTemplate/BrandtalkTemplateList.vue');
const BrandtalkTemplateForm = () => import(/* webpackChunkName: "brandtalk" */ '@/user/views/BrandtalkTemplate/BrandtalkTemplateForm.vue');
const BrandtalkTemplateInfoView = () => import(/* webpackChunkName: "brandtalk" */ '@/user/views/BrandtalkTemplate/BrandtalkTemplateInfoView.vue');

// 웹발송 페이지
const Send = () => import(/* webpackChunkName: "send" */ '@/user/views/Send/Send.vue');
const SmsTemplateList = () => import(/* webpackChunkName: "templates" */ '@/user/views/Send/SmsTemplateList.vue');

// 사용자 관리 페이지
const Users = () => import(/* webpackChunkName: "user" */ '@/user/views/Users/Users.vue');

// 정산관리-조회 페이지
const SettlementResult = () => import(/* webpackChunkName: "settlementResult" */ '@/user/views/Settlement/Result/SettlementResult.vue');
const SettlementTax = () => import(/* webpackChunkName: "settlementResult" */ '@/user/views/Settlement/Result/SettlementTax.vue');

// 통계관리-다채널 발송 조회 페이지
const MultiChannelResult = () => import('@/user/views/Statistics/SendResult/MultiChannelResult.vue')
// 통계관리-실시간 발송 조회 페이지
const RealTimeResult = () => import('@/user/views/Statistics/SendResult/RealTimeResult.vue');
// 통계관리-웹 발송 조회 페이지
const WebSendResult = () => import('@/user/views/Statistics/SendResult/WebSendResult.vue');

// 통계관리-정산 관리 페이지
const Adjustment = () => import(/* webpackChunkName: "adjustment" */ '@/user/views/Statistics/Adjustment/Adjustment.vue');

//공지사항 관리
const NoticeList = () => import('@/user/views/Board/Notice/NoticeList.vue');
const NoticeDetail = () => import('@/user/views/Board/Notice/NoticeDetail.vue');

//발신프로필 관리
const ProfileList = () => import('@/user/views/SenderProfile/ProfileList');
const ProfileRegister = () => import('@/user/views/SenderProfile/ProfileRegister');

//재판의 고객사 관리
const ResellList = () => import('@/user/views/Resell/ResellList');

//발신번호 관리
const PcbList = () => import('@/user/views/Pcb/PcbList');
let authPages = {
  path: '/user',
  component: AuthLayout,
  name: 'Auth Layout',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: Login
    },
    {
      path: 'login-success',
      name: 'LoginSuccess',
      component: LoginSuccess,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: 'otp-auth',
      name: 'OtpAuth',
      component: OtpAuth,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: 'logout',
      name: 'Logout',
      component: Logout,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: 'find-id',
      name: 'Find ID',
      component: FindId
    },
    {
      path: 'find-password',
      name: 'Find Password',
      component: FindPassword
    },
    {
      path: 'forbidden',
      name: 'Forbidden',
      component: NoPermission
    },
    { path: '*', component: NotFound }
  ]
};

const routes = [
  {
    path: '/user',
    component: DashboardLayout,
    name: 'Dashboard layout',
    redirect: '/user/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
          axios.post(`${USER_API_PREFIX}/alive`)
            .then(res => {
              if(res.data.result.session === 'anonymousUser') {
                next({path: '/user/login'})
              } else {
                next()
              }
            })
            .catch(() => {
              next({path: '/user/login'})
            });
        }
      },
      {
        path: 'users',
        name: '사용자 관리',
        component: Users
      }
    ]
  },
  {
    path: '/user/template',
    component: DashboardLayout,
    name: '알림톡 템플릿 관리',
    redirect: '/user/template/list',
    children: [
      {
        path: 'list',
        name: '알림톡 템플릿 조회',
        component: TemplateList
      },
      {
        path: 'form',
        name: '알림톡 템플릿 등록',
        component: TemplateForm
      },
      {
        path: 'multi-form',
        name: '알림톡 템플릿 대량 등록',
        component: TemplateMultiForm
      },
      {
        path: 'view/:seq',
        name: '알림톡 템플릿 정보',
        component: TemplateInfoView
      },
      {
        path: 'rest/list',
        name: '템플릿 휴면예정 목록',
        component: TemplateRestList
      }
    ]
  },
  {
    path: '/user/brand/template',
    component: DashboardLayout,
    name: '브랜드톡 템플릿 관리',
    redirect: '/user/brand/template/list',
    children: [
      {
        path: 'list',
        name: '브랜드톡 템플릿 조회',
        component: BrandtalkTemplateList
      },
      {
        path: 'form',
        name: '브랜드톡 템플릿 등록',
        component: BrandtalkTemplateForm
      },
      {
        path: 'view/:seq',
        name: '브랜드톡 템플릿 정보',
        component: BrandtalkTemplateInfoView
      },
    ]
  },
  {
    path: '/user/send',
    component: DashboardLayout,
    name: '웹발송',
    redirect: '/user/send/send',
    children: [
      {
        path: 'send',
        name: 'Web 발송',
        component: Send
      },
      {
        path: 'smsTemplateList',
        name: 'Sms 템플릿 관리',
        component: SmsTemplateList
      }
    ]
  },
  {
    path: '/user/settlement',
    component: DashboardLayout,
    name: '정산 관리',
    redirect: '/user/settlement/result',
    children: [
      {
        path: 'result',
        name: '정산정보조회',
        component: SettlementResult
      },
      {
        path: 'settlement-tax',
        name: '정산정보조회(과금부서별)',
        component: SettlementTax
      }
    ]
  },
  {
    path: '/user/statistics',
    component: DashboardLayout,
    name: '조회',
    redirect: '/user/statistics/real-time-result',
    children: [
      {
        path: 'multi-channel-result',
        name: '다채널 발송 조회',
        component: MultiChannelResult
      },
      {
        path: 'real-time-result',
        name: '실시간 발송 조회',
        component: RealTimeResult
      },
      {
        path: 'web-send-result',
        name: '웹 발송 조회',
        component: WebSendResult
      },
    ]
  },
  {
    path: '/user/board',
    component: DashboardLayout,
    name: '공지사항',
    redirect: '/user/board/notice-list',
    children: [
      {
        path: 'notice-list',
        name: '공지사항 관리',
        component: NoticeList
      },
      {
        path: 'notice-detail/:seq',
        name: '공지사항 상세',
        component: NoticeDetail
      }
    ]
  },
  {
    path: '/user/sender',
    component: DashboardLayout,
    name: '발신프로필',
    redirect: '/user/sender/profile-list',
    children: [
      {
        path: 'profile-register',
        name: '발신프로필 등록',
        component: ProfileRegister
      },
      {
        path: 'profile-list',
        name: '발신프로필 목록',
        component: ProfileList
      }
    ]
  },
  {
    path: '/user/resell',
    component: DashboardLayout,
    name: '고객 관리',
    redirect: '/user/resell/list',
    children: [
      {
        path: 'list',
        name: '고객사 관리',
        component: ResellList
      }
    ]
  },
  {
    path: '/user/sender',
    component: DashboardLayout,
    name: '발신번호 관리',
    redirect: '/user/sender/pcb-list',
    children: [
      {
        path: 'pcb-list',
        name: '발신번호 사전등록',
        component: PcbList
      }
    ]
  },
  authPages
];

export default routes;

import ko from 'vee-validate/dist/locale/ko.js';
import { Validator } from 'vee-validate';

ko.messages.required = (field) => `${field} 항목은 필수 정보입니다.`;
ko.messages.email = (field) => `${field} 항목은 유효한 이메일 형식이어야 합니다.`;
ko.messages.min = (field, length) => `${field} 항목의 값은 최소 ${length}글자이어야 합니다.`;
ko.messages.confirmed = (field, confirmedField) => `${confirmedField}와 일치하지 않습니다.`;
ko.messages.numeric = (field) => `${field} 항목은 숫자만 가능합니다.`;
ko.messages.password = (field) => `${field} 항목은 유효한 비밀번호 양식이어야 합니다.`;
ko.messages.id = (field) => `${field} 항목은 영소문자로 시작하는 6~20자의 영소문자 또는 숫자이어야 합니다.`;
ko.messages.bizNo = (field) => `${field} 항목은 유효한 사업자등록번호 양식이어야 합니다.`;
ko.messages.mobileNo = (field) => `${field} 항목은 유효한 휴대폰번호 양식이어야 합니다.`;
ko.messages.telNo = (field) => `${field} 항목은 유효한 전화번호 양식이어야 합니다.`;
ko.messages.ip = (field) => `${field} 항목은 유효한 IP 양식이어야 합니다.`;
ko.messages.macAddress = (field) => `${field} 항목은 유효한 MAC 주소 양식이어야 합니다.`;
ko.messages.url = (field) => `${field} 항목은 유효한 주소(URL) 양식이어야 합니다.`;

const veeValidateConfig = {
  fieldsBagName: 'veeFields',
  classes: true,
  validity: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  inject: false,
  locale: ko.name,
  dictionary: {
    ko: {
      messages: ko.messages,
      attributes: {
        name: '이름',
        email: '이메일',
        password: '비밀번호',
        confirmedPassword: '비밀번호 확인',
        mobileNo: '휴대폰번호',
        telNo: '전화번호',
        userId: '아이디',
        userPassword: '비밀번호',
        channelId: '채널아이디',
        description: '설명',
        managerName: '담당자명',
        managerPhoneNo: '담당자 휴대폰번호',
        clientId: '고객ID',
        clientName: '고객사명',
        agentId: 'Agent ID',
        agentName: 'Agent 명',
        substitutionYn: '대체발송여부',
        billDay: '정산일',
        bizNo: '사업자등록번호',
        channelY: '발송채널',
        sendProfileKey: '발신프로필키',
        sendProfileName: '발신프로필명',
        serverIp: '서버 IP',
        billType: '정산타입',
        technicalId: 'TECHNICAL ID',
        kkoAccount: '담당자 카카오 계정',
        clientSecret: 'API 비밀번호',
        accessTokenValidity: 'Access Token 유효시간',
        refreshTokenValidity: 'Refresh Token 유효시간',
        billName : '정산대표명',
        managerIp : '관리자 IP',
        userSeqno : '사용자 ID',
        token:'인증번호',
        subCompanyName : '고객명',
        openDate : '오픈예정일',
        sendNumber : '발신번호',
        customerTemplateCode : '고객사템플릿코드',
        templateCode : '템플릿코드',
        agentIds : 'Agent ID',
        templateHeader : '템플릿 헤더',
        bizName : '회사명',
        billId : '정산ID',
        amName : '담당AM',
        mgrName : '담당자명',
        unitCost : '단가',
        senderNo : '발신번호',
        depositor : '입금자명',
        credit : '금액',
        mobileNumber : '알람 수신자',
        comment : '내용',
        svatboYn: '특수부가사업자 대상여부',
      }
    }
  }
};

/**
 * 비밀번호 유효성 검사 룰 적용
 * 비밀번호는 영문 대문자, 영문 소문자, 숫자, 특수문자 중 2종류 이상을 조합하여 최소 10자리 이상, 또는 3종류 이상을 조합하여 최소 8자리 이상
 * 특수문자 중 " ' \ [ ] & Space(공백문자) ? % 사용하실 수 없습니다.
 */
/* eslint-disable no-new */
Validator.extend( 'password',{
  validate: value => {
    return /(^((?=.*[a-z])(?=.*[A-Z])(?=.*[\W])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W])|(?=.*\d)(?=.*[a-z])(?=.*[A-Z]))(?!.*[ㄱ-ㅎㅏ-ㅣ가-힣\[\]\?\\\t\s\x22\x27&%]).{8,}$)|(^(((?=.*\d)(?=.*\W)|(?=.*\d)(?=.*[a-zA-Z])|(?=.*[a-zA-Z])(?=.*\W)|(?=.*[a-z])(?=.*[A-Z]))(?!.*[ㄱ-ㅎㅏ-ㅣ가-힣\[\]\?\\\t\s\x22\x27&%]).{10,})$)/.test(value);
  }
});

/**
 * 사업자등록번호 유효성 검사
 */
Validator.extend( 'bizNo',{
  validate: value => {
    return /(\d{3})-(\d{2})-(\d{5})/.test(value);
  }
});

/**
 * 휴대폰번호 유효성 검사
 */
Validator.extend( 'mobileNo',{
  validate: value => {
    return /(\d{3})-(\d{3,4})-(\d{4})/.test(value);
  }
});

/**
 * 전화번호 유효성 검사
 */
Validator.extend( 'telNo',{
  validate: value => {
    return /(\d{2,3})-(\d{3,4})-(\d{4})/.test(value);
  }
});

/**
 * IP 유효성 검사
 */
Validator.extend( 'ip',{
  validate: value => {
    return /^(1|2)?\d?\d([.](1|2)?\d?\d){3}$/.test(value);
  }
});

/**
 * Mac Address 유효성 검사
 */
Validator.extend( 'macAddress',{
  validate: value => {
    return /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/.test(value);
  }
});

/**
 * 아이디 유효성 검사 룰 적용
 * 영문 소문자 및 숫자로 이루어진 최소 6자 이상
 */
/* eslint-disable no-new */
Validator.extend( 'id',{
  validate: value => {
    return /^[a-z]+[a-z0-9]{5,19}$/g.test(value);
  }
});

export default veeValidateConfig;

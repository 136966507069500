let commValidatorMixin = {
  data() {
    return {
      _firstValidated: false
    }
  },
  methods: {
    _validateBeforeSubmit(func, errorBack) {
      this.$validator.validateAll()
        .then(res => {
          if(res) {
            func();
          } else {
            if(errorBack) {
              errorBack();
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    /**
     * validate 에러 메시지 반환
     */
    _getError(field) {
      this.errors.has(field)
      if(this.errors.has(field)) {
        return this.errors.first(field)
      }
    },
    _isFormValid(field) {
      return this._firstValidated && !this.errors.has(field);
    },
  }
};

export default commValidatorMixin

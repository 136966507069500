/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from '@/shared/plugins/dashboard-plugin';
import { NotificationStore } from '@/shared/components/NotificationPlugin/index';
import App from './App.vue';
import router from './routes/router';
import store from './store/store';
import axios from 'axios';
import { Loading } from 'element-ui';
import * as filters from '@/shared/util/filters';

Vue.use(DashboardPlugin);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key])
});

// 공통 axios 요청 Interceptor
axios.interceptors.request.use((config) => {
  Loading.service({fullscreen: true});
  return config;
}, (error) => {
  console.log(error);
  Loading.service({fullscreen: true}).close();
  return Promise.reject(error);
});

// 공통 axios 응답 Interceptor
axios.interceptors.response.use((response) => {
  Loading.service({fullscreen: true}).close();
  return response;
}, (error) => {
  console.log(error);
  Loading.service({fullscreen: true}).close();

  if(error.response) {
    // 에러 상태별 로직 추가
    if(error.response.status === 401) {
      if(!router.history.current.path.match('/login')) {
        notify('danger', '세션이 만료되어 로그인 페이지로 이동합니다.');
        router.push(`/user/login?redirect=${router.history.current.path}`)
      }
    } else if(error.response.status === 403) {
      router.push(`/user/forbidden`);
    } else if(error.response.status === 500) {
      notify('danger', '서버에서 오류가 발생했습니다. 관리자에게 문의바랍니다.');
    } else {
      return Promise.resolve(error);
    }
  } else if(error.request) {
    notify('danger', '서버와의 연결이 끊어졌습니다. 관리자에게 문의바랍니다.');
  }
});

function notify(type = 'default', message) {
  NotificationStore.notify({
    message: message,
    timeout: 5000,
    icon: 'ni ni-bell-55',
    type
  });
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

import axios from 'axios';
import router from '@/user/routes/router';
import { USER_API_PREFIX, OTP_AUTH_YN } from '@/shared/util/const';

export const SessionStore = {
  namespaced: true,
  state: {
    session: {
      seqno: null,
      clientId: '',
      clientType : '',
      userId: '',
      name: '',
      roleCode: '',
      duty: '',
      position: '',
      status: '',
      authorities: [],
      authorizedMenuList: [],
      alimtalkYn: '',
      friendstalkYn: '',
      brandtalkYn: '',
      smsYn: '',
      lmsYn: '',
      mmsYn: '',
      substitutionYn: '',
      otpYn: 'N'
    }
  },
  getters: {
    getSession (state) {
      return state.session
    },
  },
  mutations: {
    setSession (state, payload) {
      state.session.seqno = payload.session.user.seqno;
      state.session.clientId = payload.session.user.clientId;
      state.session.clientType = payload.session.user.clientType;
      state.session.userId = payload.session.user.userId;
      state.session.name = payload.session.user.name;
      state.session.roleCode = payload.session.user.roleCode;
      state.session.duty = payload.session.user.duty;
      state.session.position = payload.session.user.position;
      state.session.status = payload.session.user.status;
      state.session.authorities = payload.session.authorities;
      state.session.otpYn = payload.session.user.otpYn;
      state.session.alimtalkYn = payload.session.user.alimtalkYn;
      state.session.friendstalkYn = payload.session.user.friendstalkYn;
      state.session.brandtalkYn = payload.session.user.brandtalkYn;
      state.session.smsYn = payload.session.user.smsYn;
      state.session.lmsYn = payload.session.user.lmsYn;
      state.session.mmsYn = payload.session.user.mmsYn;
      state.session.substitutionYn = payload.session.user.substitutionYn;
      state.session.authorizedMenuList = payload.menuList;
    }
  },
  actions: {
    callSession (store, payload) {
      axios.post(`${USER_API_PREFIX}/session`)
      .then(res => {
        // 세션 정보 세팅
        store.commit('setSession', res.data.result);

        // 리다이렉트 진행
        let paths = new Set();

        const getPaths = (prePath, routes) => {
          routes.forEach(item => {
            paths.add(prePath == '' ? prePath : prePath + "/" + item.path);
            if(item.hasOwnProperty('children')) getPaths(item.path, item.children)
          });
        }

        getPaths('', router.options.routes);

        let redirectUrl = '/user';
        if (payload.query.redirect && paths.has(payload.query.redirect)) {
          redirectUrl = payload.query.redirect;
        } else {
          let redirect = sessionStorage.getItem('path');
          if (redirect) {
            redirectUrl = redirect;
          }
        }
        router.push(redirectUrl);
      });
    }
  }
};

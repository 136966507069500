import swal from 'sweetalert2';
import iconv from 'iconv-lite';

let commJsUtilMixin = {
  methods: {
    /**
     * 전화번호 형식으로 변경
     * @param value
     * @returns {string}
     */
    _autoHyphenPhoneNumber (value) {
      let formatNum = ''
      if (value !== null) {
        let num = value.replace(/[^0-9]/g, '')
        if (num.length < 8) {
          formatNum = num.replace(/(\d{3})(\d{1})/, '$1-$2')
        } else if (num.length === 8) {
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
        } else if (num.length === 9) {
          formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
        } else if (num.length === 10) {
          if (num.indexOf('02') === 0) {
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
          } else {
            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
          }
        } else if (num.length === 11) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        } else {
          formatNum = value.substring(0, 13)
        }
      }

      return formatNum
    },
    /**
     * 사업자등록번호 형식으로 변경
     * @param value
     * @returns {string}
     */
    _autoHyphenBizNumber (value) {
      let formatNum = ''
      if (value !== null) {
        let num = value.replace(/[^0-9]/g, '')
        if (num.length < 5) {
          formatNum = num.replace(/(\d{3})(\d{1})/, '$1-$2')
        } else if (num.length < 6) {
          formatNum = num.replace(/(\d{3})(\d{2})/, '$1-$2')
        } else if (num.length < 11) {
          formatNum = num.replace(/(\d{3})(\d{2})(\d{1,5})/, '$1-$2-$3')
        }
      }

      return formatNum
    },
    /**
     * 키 이벤트로 IP 형식으로 문자열 변경
     */
    _autoDotIp (value) {
      let result = ''
      if (value !== null) {
        let num = value.replace(/[^0-9]/g, '')
        if (num.length < 4) {
          result = num.replace(/(\d{3})/, '$1')
        } else if (num.length < 5) {
          result = num.replace(/(\d{3})(\d{1})/, '$1.$2')
        } else if (num.length < 6) {
          result = num.replace(/(\d{3})(\d{2})/, '$1.$2')
        } else if (num.length < 7) {
          result = num.replace(/(\d{3})(\d{3})/, '$1.$2')
        } else if (num.length < 8) {
          result = num.replace(/(\d{3})(\d{3})(\d{1})/, '$1.$2.$3')
        } else if (num.length < 9) {
          result = num.replace(/(\d{3})(\d{3})(\d{2})/, '$1.$2.$3')
        } else if (num.length < 10) {
          result = num.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3')
        }
      }

      return result
    },
    /**
     * 숫자 천단위 콤마 추가
     * @param value
     * @returns {string}
     * @private
     */
    _autoCommaNumber (value) {
      let number = 0
      number = value.replace(/[^0-9]/g, '')
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    /**
     * 숫자 천단위 콤마 추가 + 소수점 이하 표시
     * @param value
     * @returns {string}
     * @private
     */
    _autoCommaNumberFloat (value) {
      let number = 0
      let decimalPart = '';
      let decimalPoint = value.indexOf('.');
      if(decimalPoint > 0){
        number = value.substring(0, decimalPoint);
        decimalPart = value.substring(decimalPoint);

        number = number.replace(/[^0-9]/g, '')
      }else{
        number = value.replace(/[^0-9]/g, '');
      }
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimalPart;
    },
    /**
     * 한글입력 불가능 처리
     * @param value
     * @returns {string}
     * @private
     */
    _noHangeul (value) {
      let str = ''
      if (value !== null) {
        str = value.replace(/[^0-9a-zA-Z]/g, '')
      }
      return str
    },
    /**
     * 숫자만 입력할 수 있도록 처리
     * @param value
     * @returns {string}
     * @private
     */
    _onlyNumber (value) {
      let str = ''
      if (value !== null) {
        str = value.replace(/[^0-9]/g, '')
      }
      return str
    },
    /**
     * Dropbox Options 데이터 생성
     * @param label 레이블
     * @param value 값
     * @param storeObj 저장대상 Object
     * @private
     */
    _makeOptionData(label, value, storeObj) {
      let options = new Object()
      options = new Object()
      options.label = label
      options.value = value

      storeObj.push(options)
    },
    /**
     * swal confirm 용 레이어
     * @param option
     * @param func
     * @private
     */
    _swalQuestion(option, func) {
      let title = option.title ? option.title : '등록 하시겠습니까?';
      let width = option.width ? option.width : 400;
      let text = option.text ? option.text : '';
      let html = option.html ? option.html : '';

      swal.fire({
        title: title,
        width: width,
        type: 'question',
        text: text,
        html: html,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        reverseButtons: true
      }).then((result) => {
        if(result.value) {
          func();
        }
      });
    },
    /**
     * 올해 부터 20년 전 까지의 연도 목록을 조회
     */
    _years() {
      let currentYear = new Date().getFullYear(), years = [];
      let startYear = currentYear-20;
      while (startYear <= currentYear) {
        years.push({
          label: `${startYear}년`,
          value: startYear
        });
        startYear++;
      }

      return {
        year: currentYear,
        list: years.sort(() => {
          return -1;
        })
      }
    },
    /**
     * 월(month) 목록을 조회
     * @param emptyRowYn "선택" Row 추가 여부
     */
    _months(emptyRowYn) {
      let currentMonth = new Date().getMonth()+1, months = [];
      let month = 1;

      // "선택" Row 추가 여부 설정
      if(emptyRowYn === 'Y') {
        months.push({
          label: '선택',
          value: ''
        });
      }

      while ( month <= 12 ) {
        months.push({
          label: `${month}월`,
          value: month
        });
        month++;
      }

      return {
        month: currentMonth,
        list: months
      };
    },
    /**
     * 월(month) 목록을 조회
     * @param emptyRowYn "선택" Row 추가 여부
     */
    _days(emptyRowYn) {
      let currentDay = new Date().getDate(), days = [];
      let day = 1;

      // "선택" Row 추가 여부 설정
      if(emptyRowYn === 'Y') {
        days.push({
          label: '선택',
          value: ''
        });
      }

      while ( day <= 31 ) {
        days.push({
          label: `${day}일`,
          value: day
        });
        day++;
      }

      return {
        day: currentDay,
        list: days
      };
    },
    /**
     * 년/월/일 목록을 조회
     * @param emptyRowYn "선택" Row 추가 여부
     */
    _fullDays(emptyRowYn, period) {
      let date = new Date();
      date.setDate(date.getDate() - period);

      let currentYear = new Date().getFullYear(), years = [];
      let startYear = currentYear-20;
      while (startYear <= currentYear) {
        years.push({
          label: `${startYear}년`,
          value: startYear
        });
        startYear++;
      }

      let currentMonth = date.getMonth()+1, months = [];
      let month = 1;

      // "선택" Row 추가 여부 설정
      if(emptyRowYn === 'Y') {
        months.push({
          label: '선택',
          value: ''
        });
      }

      while ( month <= 12 ) {
        months.push({
          label: `${month}월`,
          value: month
        });
        month++;
      }

      let currentDay = date.getDate(), days = [];
      let day = 1;

      // "선택" Row 추가 여부 설정
      if(emptyRowYn === 'Y') {
        days.push({
          label: '선택',
          value: ''
        });
      }

      while ( day <= 31 ) {
        days.push({
          label: `${day}일`,
          value: day
        });
        day++;
      }

      return {
        year: currentYear,
        yearList: years.sort(() => {
          return -1;
        }),
        month: currentMonth,
        monthList: months,
        day: currentDay,
        dayList: days
      };
    },
    _maxLengthTextEucKr(str, length){
      return iconv.decode(iconv.encode(str, 'euc-kr').slice(0, length), 'euc-kr').replace(/\uFFFD/g, '');
    },
    _eucKrLength(str, length){
      return iconv.encode(str, 'euc-kr').length;
    },
    /**
     * 파일 사이즈 -> 바이트 변환
     * @param bytes
     * @returns {string}
     */
    _bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if(bytes === 0) return '0 Byte';
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    /**
     * 파일 정보 노출 조합
     * @param fileInfo
     * @returns {string}
     */
    _getFileInfoText(fileInfo) {
      return fileInfo.name + ' (' + this._bytesToSize(fileInfo.fileSize) + ')'
    },
    /**
     * 확장자에 따른 파일 아이콘
     * @param ext
     * @returns {string}
     */
    _getFileIconByExt(ext) {
      let iconClass;
      switch (ext) {
        case 'PDF':
          iconClass = 'fa-file-pdf'
          break;
        case 'XLS':
        case 'XLSX':
          iconClass = 'fa-file-excel'
          break;
        case 'DOC':
        case 'DOCX':
          iconClass = 'fa-file-word'
          break;
        case 'PPT':
        case 'PPTX':
          iconClass = 'fa-file-powerpoint'
          break;
        case 'CSV':
          iconClass = 'fa-file-csv'
          break;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
          iconClass = 'fa-file-image'
          break;
        default:
          iconClass = 'fa-file'
          break;
      }
      return iconClass;
    },
    /**
     * 순환 참조를 하는 리턴값에 대한 JSON 변환 작업
     * @param obj
     * @param indent
     * @returns {string}
     * @private
     */
    _safeStringify(obj, indent = 2) {
      let cache = [];
      const retVal = JSON.stringify(
        obj,
        (key, value) =>
          typeof value === "object" && value !== null
            ? cache.includes(value)
            ? undefined // 중복 참조에 대해서는 키 제거
            : cache.push(value) && value // 컬렉션에 값 저장
            : value,
        indent
      );
      cache = null;
      return retVal;
    }
  }
};

export default commJsUtilMixin
